<template>
	<div class="pui-form">
		<equipamiento-modals :modelName="modelName"></equipamiento-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<equipamiento-form-header :modelPk="modelPk"></equipamiento-form-header>
		</pui-form-header>
		<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary" show-arrows>
			<v-tab :key="0" :href="'#maintab'">{{ $t('equipamiento.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#documentos'" v-if="!isCreatingElement">{{ $t('equipamiento.documentostab') }}</v-tab>
			<v-tab :key="2" :href="'#equipamientosqr'" v-if="!isCreatingElement">{{ $t('equipamiento.equipamientosqrtab') }}</v-tab>
			<v-tab :key="2" :href="'#equipamientosagad'" v-if="!isCreatingElement && tienePermisoAA">{{
				$t('equipamiento.equipamientosagadtab')
			}}</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tabmodel">
			<v-tab-item :key="0" lazy :value="'maintab'">
				<v-layout row wrap class="pui-form-layout">
					<v-flex xs12>
						<pui-field-set :title="getTitleDatosIdentificativos" highlighted class="pt-3">
							<v-layout row wrap v-if="!isCreatingElement">
								<pui-number-field
									:id="`idequipamiento-equipamiento`"
									v-model="model.idequipamiento"
									:label="$t('equipamiento.idequipamiento')"
									:disabled="formDisabled"
									toplabel
									integer
									min="0"
									max="9999999999"
								></pui-number-field>
							</v-layout>
							<v-layout row wrap>
								<v-flex xs3 md2>
									<pui-text-field
										:id="`tipodeclaracion-equipamiento`"
										v-model="model.tipodeclaracion"
										:label="$t('equipamiento.tipodeclaracion')"
										:disabled="formDisabled"
										toplabel
										maxlength="1"
									></pui-text-field>
								</v-flex>
								<v-flex xs6 md3>
									<pui-text-field
										:id="`numdeclaracion-equipamiento`"
										v-model="model.numdeclaracion"
										:label="$t('equipamiento.numdeclaracion')"
										:disabled="formDisabled"
										toplabel
										maxlength="11"
									></pui-text-field>
								</v-flex>
								<v-flex xs3 md2>
									<pui-text-field
										:id="`matricula-equipamiento`"
										v-model="model.matricula"
										:label="$t('equipamiento.matricula')"
										:disabled="formDisabled"
										toplabel
										maxlength="30"
									></pui-text-field>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
					<v-flex xs12>
						<pui-field-set :title="getTitleDatos" class="pt-3">
							<v-layout row wrap>
								<v-flex xs6 md4>
									<pui-text-field
										:id="`escala-equipamiento`"
										v-model="model.escala"
										:label="$t('equipamiento.escala')"
										:disabled="formDisabled"
										toplabel
										maxlength="14"
									></pui-text-field>
								</v-flex>
								<!-- CONSIGBUQUENIF -->
								<v-flex xs6 md4>
									<pui-text-field
										:id="`consigbuquenif-equipamiento`"
										v-model="model.consigbuquenif"
										:label="$t('equipamiento.consigbuquenif')"
										:disabled="formDisabled"
										toplabel
										maxlength="17"
									></pui-text-field>
								</v-flex>
								<!-- CONSIGMERC -->
								<v-flex xs6 md2>
									<pui-text-field
										:id="`consigmerc-equipamiento`"
										v-model="model.consigmerc"
										:label="$t('equipamiento.consigmerc')"
										:disabled="formDisabled"
										toplabel
										maxlength="30"
									></pui-text-field>
								</v-flex>
								<!-- CONSIGMERCNIF -->
								<v-flex xs6 md2>
									<pui-text-field
										:id="`consigmercnif-equipamiento`"
										v-model="model.consigmercnif"
										:label="$t('equipamiento.consigmercnif')"
										:disabled="formDisabled"
										toplabel
										maxlength="17"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<!-- ETA -->
								<v-flex xs4 lg3>
									<pui-date-field
										:id="`eta-equipamiento`"
										v-model="model.eta"
										:label="$t('equipamiento.eta')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
								<!-- ETD -->
								<v-flex xs4 lg2>
									<pui-date-field
										:id="`etd-equipamiento`"
										v-model="model.etd"
										:label="$t('equipamiento.etd')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<!-- CODTIPOEQUIPAMIENTO -->
								<v-flex xs4 md3 lg2>
									<pui-text-field
										:id="`codtipoequipamiento-equipamiento`"
										v-model="model.codtipoequipamiento"
										:label="$t('equipamiento.codtipoequipamiento')"
										:disabled="formDisabled"
										toplabel
										maxlength="3"
									></pui-text-field>
								</v-flex>
								<!-- CODTIPOTAMANYO -->
								<v-flex xs4 md2>
									<pui-text-field
										:id="`codtipotamanyo-equipamiento`"
										v-model="model.codtipotamanyo"
										:label="$t('equipamiento.codtipotamanyo')"
										:disabled="formDisabled"
										toplabel
										maxlength="4"
									></pui-text-field>
								</v-flex>
								<!-- CODLLENOVACIO -->
								<v-flex xs4 md2>
									<pui-text-field
										:id="`codllenovacio-equipamiento`"
										v-model="model.codllenovacio"
										:label="$t('equipamiento.codllenovacio')"
										:disabled="formDisabled"
										toplabel
										maxlength="1"
									></pui-text-field>
								</v-flex>
								<!-- UNIDADESVACIOS -->
								<v-flex xs6 md2>
									<pui-number-field
										:id="`unidadesvacios-equipamiento`"
										v-model="model.unidadesvacios"
										:label="$t('equipamiento.unidadesvacios')"
										:disabled="formDisabled"
										toplabel
										integer
										min="0"
										max="99999"
									></pui-number-field>
								</v-flex>
								<!-- TARA -->
								<v-flex xs6 md2>
									<pui-number-field
										:id="`tara-equipamiento`"
										v-model="model.tara"
										:label="$t('equipamiento.tara')"
										:disabled="formDisabled"
										toplabel
										integer
										decimals="2"
										min="0"
										max="99999999"
									></pui-number-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<!-- CAMIONLSP -->
								<v-flex xs6 md4>
									<pui-text-field
										:id="`camionlsp-equipamiento`"
										v-model="model.camionlsp"
										:label="$t('equipamiento.camionlsp')"
										:disabled="formDisabled"
										toplabel
										maxlength="20"
									></pui-text-field>
								</v-flex>
								<!-- LSP -->
								<v-flex xs6 md4>
									<pui-text-field
										:id="`lsp-equipamiento`"
										v-model="model.lsp"
										:label="$t('equipamiento.lsp')"
										:disabled="formDisabled"
										toplabel
										maxlength="1"
									></pui-text-field>
								</v-flex>
								<!-- FECHALSP -->
								<v-flex xs6 md4>
									<pui-date-field
										:id="`fechalsp-equipamiento`"
										v-model="model.fechalsp"
										:label="$t('equipamiento.fechalsp')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<!-- UBICACION -->
								<v-flex xs2 md2>
									<pui-text-field
										:id="`ubicacion-equipamiento`"
										v-model="model.ubicacion"
										:label="$t('equipamiento.ubicacion')"
										:disabled="formDisabled"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-flex>
								<!-- CODTERMINAL -->
								<v-flex xs6 md2 lg4>
									<pui-text-field
										:id="`codterminal-equipamiento`"
										v-model="model.codterminal"
										:label="$t('equipamiento.codterminal')"
										:disabled="formDisabled"
										toplabel
										maxlength="50"
									></pui-text-field>
								</v-flex>
								<!-- FECCARGADESCARGA -->
								<v-flex xs6 md4 lg3>
									<pui-date-field
										:id="`feccargadescarga-equipamiento`"
										v-model="model.feccargadescarga"
										:label="$t('equipamiento.feccargadescarga')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
								<!-- FECENTRADASALIDA -->
								<v-flex xs6 md4 lg3>
									<pui-date-field
										:id="`fecentradasalida-equipamiento`"
										v-model="model.fecentradasalida"
										:label="$t('equipamiento.fecentradasalida')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<!-- NUMBL -->
								<v-flex xs6 md4>
									<pui-text-field
										:id="`numbl-equipamiento`"
										v-model="model.numbl"
										:label="$t('equipamiento.numbl')"
										:disabled="formDisabled"
										toplabel
										maxlength="100"
									></pui-text-field>
								</v-flex>
								<!-- FECAUTORIZACION -->
								<v-flex xs6 md4>
									<pui-date-field
										:id="`fecautorizacion-equipamiento`"
										v-model="model.fecautorizacion"
										:label="$t('equipamiento.fecautorizacion')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
								<!-- BAJA -->
								<v-flex xs6 md4>
									<pui-text-field
										:id="`baja-equipamiento`"
										v-model="model.baja"
										:label="$t('equipamiento.baja')"
										:disabled="formDisabled"
										toplabel
										maxlength="1"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<!-- LUGARORIGEN -->
								<v-flex xs6>
									<pui-text-field
										:id="`lugarorigen-equipamiento`"
										v-model="model.lugarorigen"
										:label="$t('equipamiento.lugarorigen')"
										:disabled="formDisabled"
										toplabel
										maxlength="5"
									></pui-text-field>
								</v-flex>
								<!-- LUGARDESTINO -->
								<v-flex xs6>
									<pui-text-field
										:id="`lugardestino-equipamiento`"
										v-model="model.lugardestino"
										:label="$t('equipamiento.lugardestino')"
										:disabled="formDisabled"
										toplabel
										maxlength="5"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<!-- PUERTOCARGADESCARGA -->
								<v-flex xs4>
									<pui-text-field
										:id="`puertocargadescarga-equipamiento`"
										v-model="model.puertocargadescarga"
										:label="$t('equipamiento.puertocargadescarga')"
										:disabled="formDisabled"
										toplabel
										maxlength="5"
									></pui-text-field>
								</v-flex>
								<!-- PUERTOTRANSBORDO -->
								<v-flex xs4>
									<pui-text-field
										:id="`puertotransbordo-equipamiento`"
										v-model="model.puertotransbordo"
										:label="$t('equipamiento.puertotransbordo')"
										:disabled="formDisabled"
										toplabel
										maxlength="5"
									></pui-text-field>
								</v-flex>
								<!-- TRANSPORTEANTPOS -->
								<v-flex xs4>
									<pui-text-field
										:id="`transporteantpos-equipamiento`"
										v-model="model.transporteantpos"
										:label="$t('equipamiento.transporteantpos')"
										:disabled="formDisabled"
										toplabel
										maxlength="3"
									></pui-text-field>
								</v-flex>
							</v-layout>
						</pui-field-set>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement && false"></pui-form-mini-audit>
					</v-flex>
				</v-layout>
			</v-tab-item>
			<v-tab-item :key="1" :value="'documentos'" lazy v-if="!isCreatingElement && !isModalDialog">
				<pui-master-detail
					componentName="documentosaduaneroequipamiento-grid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ idequipamiento: 'idequipamiento' }"
					:modalDialog="isModalDialog"
					:formDisabled="true"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="2" :value="'equipamientosqr'" lazy v-if="!isCreatingElement && !isModalDialog">
				<pui-master-detail
					componentName="equipamientoqrequip-grid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ idequipamiento: 'idequipamiento' }"
					:modalDialog="isModalDialog"
					:formDisabled="true"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="1" :value="'equipamientosagad'" lazy v-if="!isCreatingElement && !isModalDialog">
				<pui-master-detail
					componentName="equipamientoagenteaduana-grid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ idequipamiento: 'idequipamiento' }"
					:modalDialog="isModalDialog"
					:formDisabled="true"
				></pui-master-detail>
			</v-tab-item>
		</v-tabs-items>
		<!-- Page Footer -->
		<pui-form-footer v-if="!isModalDialog">
			<pui-form-footer-btns
				:formDisabled="formDisabled"
				:saveDisabled="saving"
				:saveAndNew="saveAndNew"
				:saveAndUpdate="saveAndUpdate"
				:save="save"
				:back="back"
			></pui-form-footer-btns>
		</pui-form-footer>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import equipamientoActions from './EquipamientoActions';
import equipamientoModals from './EquipamientoModals.vue';
export default {
	name: 'equipamiento-form',
	mixins: [PuiFormMethodsMixin],

	components: {
		'equipamiento-modals': equipamientoModals
	},
	data() {
		return {
			modelName: 'equipamiento',
			tabmodel: 'maintab',
			actions: equipamientoActions.formActions,
			myModelLoaded: false
		};
	},
	methods: {
		afterGetData() {
			this.myModelLoaded = true;
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel) {
				if (thisModel.tabFromAction) {
					this.tabmodel = thisModel.tabFromAction;
					delete thisModel.tabFromAction;
				}
			}
		}
	},
	computed: {
		getTitleDatosIdentificativos() {
			return this.$t('form.equipamiento.fieldset.ids');
		},
		getTitleDatos() {
			return this.$t('form.equipamiento.fieldset.dt');
		},
		tienePermisoAA() {
			return this.$store.getters.hasFunctionality('READ_EQUIPAMIENTOAGENTEADUANA');
		}
	}
};
</script>
